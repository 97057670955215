<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <template #tit>제휴문의</template>

            <form-input code="inquiry-partner" skin="inquiry-partner"></form-input>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import FormInput from "@/components/client/form/form-input.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        FormInput,
    },
};
</script>
