var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-arrow-top-right",
    attrs: {
      "viewBox": "0 0 16 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_2315_62025)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.49815e-07 14.123L10.3197 3.78048L2.54771 3.78048L2.56754 0.499944L16 0.499945L16 13.9364L12.6989 13.9364L12.7187 6.18687L2.3891 16.5098L5.49815e-07 14.123Z"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_2315_62025"
    }
  }, [_c('rect', {
    attrs: {
      "width": "16",
      "height": "16",
      "fill": "white",
      "transform": "translate(0 0.5)"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }