<template>
    <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-arrow-top-right">
        <g clip-path="url(#clip0_2315_62025)">
            <path d="M5.49815e-07 14.123L10.3197 3.78048L2.54771 3.78048L2.56754 0.499944L16 0.499945L16 13.9364L12.6989 13.9364L12.7187 6.18687L2.3891 16.5098L5.49815e-07 14.123Z"/>
        </g>
        <defs>
        <clipPath id="clip0_2315_62025">
            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.icon-arrow-top-right {
    width: 16px;
}
</style>